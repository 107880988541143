<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <h1 v-if="device && device.deviceExtension" class="my-12 text-3xl font-bold leading-7 text-gray-900">{{$t("home.device")}}: {{ device.parameters['numer_seryjny'] }}</h1>
    <div class="flex my-6 -mx-3 divide-x divide-gray-200">
      <div class="w-1/3 px-3">
        <div>
          <h3 class="font-bold text-gray-500 text-xl">{{$t("device.deviceData")}}:</h3>
          <div class="mt-3 col-span-1 bg-white rounded-lg border border-gray-200 shadow divide-y divide-gray-200 overflow-hidden flex flex-col justify-between">
            <div v-if="!device" class="flex align-center justify-center py-10">
              <CogIcon class="h-12 w-12 text-ifgreen animate-spin" aria-hidden="true" />
            </div>
            <div v-else class="divide-y divide-y-gray-200">
              <div v-for="param in deviceParams" :key="param.label" class="border-t border-gray-200">
                <dl>
                  <div  class="px-4 py-3 grid grid-cols-2 gap-4 px-6" :class="param.value && param.value.slice(-1) === '*' && 'border border-yellow-600'">
                    <dt class="text-sm font-medium text-gray-500">
                      {{ param.label }}
                    </dt>
                    <dd class="text-sm text-gray-900 sm:mt-0 ">
                      {{ param.value }} <ExclamationTriangleIcon v-if="param.value && param.value.slice(-1) === '*'" class="h-5 w-5 inline-block text-yellow-600" aria-hidden="true" />
                    </dd>
                  </div>
                </dl>
              </div>
              <div v-if="device.deviceExtension && role.name=='superadmin'" class="p-6">
                <p class="text-sm text-gray-500">
                  Zdjęcia produkcyjne:
                </p>
                <div class="w-full mt-3 space-y-1 divide-y divide-gray-200">
                  <a v-for="(entry, index) in devicePhotos" :key="index" :href="entry.value" target="_blank" class="pt-2 pb-1 text-sm font-medium text-gray-900 w-full flex justify-between">
                    {{ entry.label }}
                    <ArrowDownTrayIcon class="w-4 h-4" />
                  </a>
                  <p class="text-sm font-medium text-gray-900" v-if="!devicePhotos.length">
                    Nie dodano jeszcze żadnych zdjęć
                  </p>
                </div>
              </div>
              <div v-if="device.deviceExtension && role.name=='superadmin'" class="p-6">
                <div class="flex justify-between">
                  <p class="text-sm text-gray-500">
                    Historia:
                  </p>
                  <div class="space-x-1">
                    <span v-if="device.installation" title="Uruchomione" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                      Uruch.
                    </span>
                    <span v-if="!device.deviceExtension" title="Dodane przez instalatora" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                      Inst.
                    </span>
                  </div>
                </div>
                <div v-if="logs" class="w-full pt-3 divide-y divide-gray-200">
                  <div class="flex -mx-2">
                    <p class="w-1/6 px-2 py-2 text-left text-xs text-gray-500">
                      etap
                    </p>
                    <p class="w-2/6 px-2 py-2 text-left text-xs text-gray-500">
                      wykonany przez
                    </p>
                    <p class="w-1/2 px-2 py-2 text-right text-xs text-gray-500">
                      data rozpoczęcia
                    </p>
                  </div>
                  <div v-for="(log, index) in logs" :key="index" class="flex -mx-2" :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                    <p class="w-1/6 px-2 py-2 whitespace-nowrap text-sm text-gray-500">{{ log.movedToStep }}</p>
                    <p class="w-2/6 px-2 py-2 whitespace-nowrap text-sm text-gray-500">{{ log.endedBy }}</p>
                    <p class="w-1/2 px-2 py-2 whitespace-nowrap text-sm text-gray-500 text-right">{{ new Date(log.timestamp).toLocaleString('pl-PL') }}</p>
                  </div>
                </div>
              </div>
              <div v-if="device.deviceExtension && device.deviceExtension.note" class="w-full">
                <div class="w-full p-6">
                  <p class="text-sm text-gray-500 mb-3">
                    Uwagi:
                  </p>
                  <p class="text-sm font-medium text-gray-900 ">
                    {{ device.deviceExtension.note }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <h3 class="font-bold text-gray-500 text-xl">{{$t("installation.installationData")}}:</h3>
          <AdminLoadingCard
            v-if="!installation || !installation.id"
            :loading-condition="!installation"
            :display-condition="installation && !installation.id"
            :placeholder="$t('public.infos.deviceNotInstalled')"
          />
          <AdminInstallationCard
            v-else
            :installation="installation"
            :deviceDetails="true"
            class="mt-3"
          />
        </div>
      </div>
      <div class="w-1/3 px-3">
        <h3 class="font-bold text-gray-500 text-xl">{{$t("home.inspection.inspections")}}:</h3>
        <AdminLoadingCard
          v-if="!inspections || !inspections.length"
          :loading-condition="!inspections"
          :display-condition="inspections && !inspections.length"
          :placeholder="$t('public.infos.inspectionsNotAvailable')"
        />
        <div v-else class="space-y-6 mt-3">
          <InspectionCard
            v-for="inspection in inspections"
            :inspection="inspection"
            :shortened="true"
            :hide-controls="true"
            :key="inspection.id"
          />
        </div>
      </div>
      <div class="w-1/3 px-3">
        <h3 class="font-bold text-gray-500 text-xl">{{$t("service.services")}}:</h3>
        <AdminLoadingCard
          v-if="!services || !services.length"
          :loading-condition="!services"
          :display-condition="services && !services.length"
          :placeholder="$t('public.infos.servicesNotAvailable')"
        />
        <div v-else class="space-y-6 mt-3">
          <ServiceCard
            v-for="service in services"
            :service="service"
            :shortened="true"
            :hide-controls="true"
            :key="service.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { ArrowLeftIcon, CheckCircleIcon } from '@heroicons/vue/24/outline'
import AdminInstallationCard from '@/components/admin/AdminInstallationCard.vue'
import InspectionCard from '@/components/inspections/InspectionCard.vue'
import ServiceCard from '@/components/services/ServiceCard.vue'
import AdminLoadingCard from '@/components/admin/AdminLoadingCard.vue'
import SearchBar from '@/components/inputs/SearchBar.vue';
import Modal from '@/components/layout/Modal.vue';
import { CogIcon, ArrowDownTrayIcon } from '@heroicons/vue/24/outline'
import { api } from "@/assets/js/api-client.js";
import { errorNotification, serializeParams } from "@/assets/js/helpers.js";
import { mapState } from 'vuex'

export default {
  name: "AdminDeviceDetails",
  components: {
    AdminInstallationCard,
    InspectionCard,
    ServiceCard,
    AdminLoadingCard,
    Modal,
    SearchBar,
    CogIcon,
    ArrowDownTrayIcon
  },
  data () {
    return {
      device: null,
      logs: null,
      installation: null,
      inspections: null,
      services: null
    }
  },
  computed: {
    ...mapState(['role', 'organisation']),
    deviceParams () {
      if (this.device.parameters) {
        const deviceData = this.device.schemaVersion.parametersSchema.map(field => {
          return {label: field.label, value: this.device.parameters[field.name]}
        }).filter(item => {return item.value})
        deviceData.unshift({label: 'Rodzaj urządzenia', value: this.device.deviceTemplate.name})
        return deviceData
      }
      return []
    },
    devicePhotos () {
      const { device } = this
      const links = [
        {label: "hydraulika", value: device.deviceExtension.hydraulicsImage},
        {label: "manometry", value: device.deviceExtension.manometersImage},
        {label: "manometry 2", value: device.deviceExtension.manometers2Image},
        {label: "termometr", value: device.deviceExtension.termometerImage},
        {label: "elektryka", value: device.deviceExtension.electricsImage},
        {label: "elektryka 2", value: device.deviceExtension.electrics2Image},
        {label: "szafa - lewa strona", value: device.deviceExtension.fromLeftImage},
        {label: "szafa - prawa strona", value: device.deviceExtension.fromRightImage},
        {label: "szafa - spakowana", value: device.deviceExtension.packedImage},
        {label: "etykieta wysyłki", value: device.deviceExtension.deliveryLabelImage}
      ]
      return links.filter((link) => {return link.value})
    },
  },
  async created () {
    const id = this.$route.params.id
    const endpoint = 'custom_devices'
    if (this.role.name == 'superadmin') await this.$store.dispatch('getDeviceTemplates', true)
    api.get(`/${endpoint}/${id}`).then(result => {
      this.device = serializeParams(result.data)
      console.log(this.device)
      this.installation = this.device.installation ?? {}
      if (this.installation) this.installation.customDevice = this.device
      if (this.device.deviceExtension) {
        api.get(`/device_extensions/${this.device.deviceExtension.id}/device_logs`).then(result => {
          console.log('logs:', result.data)
          this.logs = result.data
        }).catch(err => {
          errorNotification(this, err)
        })
      }
    }).catch(err => {
      errorNotification(this, err)
    })
    api.get(`/${endpoint}/${id}/inspections`).then(result => {
      this.inspections = result.data
    }).catch(err => {
      errorNotification(this, err)
    })
    api.get(`/${endpoint}/${id}/services`).then(result => {
      this.services = result.data
    }).catch(err => {
      errorNotification(this, err)
    })
  }
};
</script>
